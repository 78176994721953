<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-16 18:01:10
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 18:04:31
 * @Description: 导入账单
 * @FilePath: \src\views\App\Bill\ImportBill.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <!-- 文件上传组件 -->
        <el-row
          v-if="shou_upload"
          type="flex"
          justify="center"
          align="middle"
          style="height: 95%"
        >
          <el-col :span="24">
            <el-upload
              class="upload"
              drag
              action=""
              name="file"
              :http-request="_FileUpload"
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传.xlsx/.xls文件，且不超过10MB
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </el-card>

      <el-divider></el-divider>

      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_CreateNewChannelBillingMode()"
        v-if="id == 0"
        >提交</el-button
      >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-check"
        @click="_UpdateChannelBillingMode()"
        v-else
        >保存</el-button
      >
      <el-button size="small" icon="el-icon-close" @click="isShow = false"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { uploadFile } from "@/api/api"; //公共api 图像文件上传接口
export default {
  data() {
    return {
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      file_name: "", //已上传的文件名称
      shou_upload: true, //显示文件上传框
    };
  },
  methods: {
    // 打开
    open() {
      this.title = "账单上传";
      this.loading = false;
      this.isShow = true; //显示组件
    },

    //文件上传
    _FileUpload(file) {
      let formData = new FormData(); // 声明一个FormData对象
      formData.append(file.filename, file.file, file.file.name);
      uploadFile(formData)
        .then((res) => {
          this.file_name = res.data;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>

<style>
.upload {
  height: 100%;
  margin: auto;
  text-align: center;
}
</style>
